<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Certificate/curriculum"
            >课程证书 /
          </router-link>
          <span class="crumbs_item crumbs_last">获证人员</span>
        </span>
      </div>
      <div class="sider_right">
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ i + 1 }}
          </div>
        </template>
        <template slot="mobile" slot-scope="text, item">
        {{ item.mobile ? $encryption('empty','phone',item.mobile) : "--" }}
        </template>
        <template slot="idNo" slot-scope="text, item">
        {{ item.idNo ? $encryption('empty','idCard',item.idNo) : "--" }}
        </template>
        <!-- 证书模板 -->
        <template slot="operation" slot-scope="text, record">
          <div class="btn_router_link">
            <div style="position: relative; overflow: hidden">
              <span
                v-if="record.certifPicture"
                style="color: #3681ef; cursor: pointer"
              >
                查看</span
              >
              <span v-else>--</span>
              <viewer>
                <img
                  style="
                    position: absolute;
                    top: -10px;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                  "
                  class="inneer_img"
                  :src="record.certifPicture"
                />
              </viewer>
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号码",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idNo",
    scopedSlots: { customRender: "idNo" },
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "certifCode",
  },
  {
    title: "发证时间",
    align: "center",
    dataIndex: "getcertifTime",
  },
  {
    title: "电子证书",
    align: "center",
    dataIndex: "code6",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      id: null, // id
      name: "",
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.$ajax({
        url: "/hxclass-management/course-certif/manage/selCourseStudyCertif",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          keyword: this.name,
          courseId: this.id,
        },
      }).then((res) => {
        // console.log(res);
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.id = this.$route.query.id;
    this.getdataList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
a.crumbs_item {
  color: #333;
}
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.sider_right {
  display: flex;
  .ant-input {
    margin-left: 20px;
  }
}
</style>
